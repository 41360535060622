<template>

    <!-- the imprint -->
    <div style="color: var(--color-grey);">
        
        <br>
        <p>
        Information pursuant to the Interstate Media Treaty § 18 para. 2<br>
        <br>
        Institute for Textile Technology Augsburg gGmbH<br>
        Prof. Dr. -Ing. Mesut Cetin<br>
        Am Technologiezentrum 5<br>
        86159 Augsburg<br>
        Deutschland<br>
        <br>
        Represented by:  Prof. Dr.-Ing. Mesut Cetin, MBA<br>
        <br>
        Contact:<br>
        Phone: +49 821 8090 34 0<br>
        E-Mail: info@ita-augsburg.de <br>
        <br>
        Commercial Register: Augsburg, HRB 29131<br>
        <br>
        Sales tax number: DE302479598<br>
        <br>
        Tax number: 103 / 147 / 01661<br>
        <br>
        <br>
        </p>
        <h2>Disclaimer</h2>
        <p>
        Liability for links:<br>
        Our offer contains links to external websites of third parties, on whose contents we have no influence. Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the pages is always responsible for the content of the linked pages. The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not recognizable at the time of linking. However, a permanent control of the contents of the linked pages is not reasonable without concrete evidence of a violation of the law. If we become aware of any infringements, we will remove such links immediately.<br>
        <br>
        Liability for content:<br>
        The contents of our pages were created with the utmost care. However, we cannot guarantee the accuracy, completeness and timeliness of the content. As a service provider, we are responsible for our own content on these pages under the general laws according to § 7 para.1 TMG. According to § 8 to 10 TMG, we are not obligated to monitor transmitted or stored information or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information according to general laws remain unaffected. However, liability in this regard is only possible from the point in time at which a concrete infringement of the law becomes known. If we become aware of such infringements, we will remove this content immediately.<br>
        <br>
        Copyright:<br>
        The content and works created by the site operators on these pages are subject to German copyright law. Duplication, processing, distribution, or any form of commercialization of such material beyond the scope of the copyright law shall require the prior written consent of its respective author or creator. Downloads and copies of this site are only permitted for private, non-commercial use. Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected. In particular, third-party content is identified as such. Should you nevertheless become aware of a copyright infringement, please inform us accordingly. If we become aware of any infringements, we will remove such content immediately.<br>
        <br>
        </p>

    </div>
</template>